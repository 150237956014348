@import "app-variables";

:root {
  // Fonts
  --app-primary-font-family: #{$app-primary-font-family};

  // Colors
  --app-primary-color: #{$app-primary-color};

  // Form
  --app-form-margin: #{$app-form-margin};
  --app-form-group-margin: #{$app-form-group-margin};
}
