@import "material-colors/dist/colors.sass";

// Fonts
$app-primary-font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Colors
$app-primary-color: #f68508;

// Layout
$app-layout-margin: 20px;
$app-header-height: 42px;
$app-body-padding-top: $app-header-height + $app-layout-margin;
$app-header-z-index: 10000;
$app-ion-app-z-index: 10001;
$app-drawer-z-index: 11000;

// Form
$app-form-margin: $app-layout-margin;
$app-form-group-margin: 15px;
